import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AuthHeader from "../../layout/AuthHeader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import * as Yup from "yup";
import callApi from "../../services/api/apiClient";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { BeatLoader } from "react-spinners";
import { AccessTokenSlice } from "../../redux/reducers/reducer";
import { dispatchStore } from "../../lib/dispatchStore";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";

export default function SignUp() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [serverError, setServerError] = useState(null);

  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const SignUpSchema = Yup.object().shape({
    name: Yup.string().required(getLanguageFile.name_required),
    phone: Yup.string().required(getLanguageFile.password_require),
    password: Yup.string()
      .min(6, getLanguageFile.password_much_six_char)
      .required(getLanguageFile.password_require),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], getLanguageFile.password_not_match)
      .required(getLanguageFile.confirm_new_password),
    referral_code: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      password: "",
      password_confirmation: "",
      referral_code: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SignUpSchema,
    onSubmit: (values, actions) => {
      callApi("auth/signup")
        .withBody({
          name: values.name,
          phone: values.phone,
          password: values.password,
          password_confirmation: values.password_confirmation,
          referral_code: values.referral_code,
        })
        .loadingGroup("signup")
        .execute()
        .then((res) => {
          if (res?.token) {
            dispatchStore(
              AccessTokenSlice.actions.setAccessToken("Bearer " + res?.token)
            );
          }
          if (res?.status_code == 200) {
            actions.resetForm();
            navigate("/create-profile");
          } else {
            setServerError(res?.message);
          }
        })
        .catch((err) => console.log("error", err));
      actions.setSubmitting(false);
    },
  });

  const handleClose = () => {
    setServerError(null);
  };

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={handleClose}
        />
      )}

      <AuthHeader title={getLanguageFile.sign_up} backkey={true} />

      <div
        id="body"
        className="auth"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <section className="m-tb-32">
          <h1 className="fs-24 font-semibold leading-[30px] text-red-500 text-center">
            Lucky Mall
          </h1>
        </section>

        <section className="container p-lr-16 m-b-8">
          <form action="" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.username}
                <span className="text-red-500 pe-2">*</span>
                {formik?.touched.name && formik?.errors.name && (
                  <span className="text-red-500">{formik.errors.name}</span>
                )}
              </label>

              <input
                id="name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                type="text"
                placeholder={getLanguageFile.type_username}
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />
            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.phone_number}
                <span className="text-red-500 pe-2">*</span>
                {formik?.touched.phone && formik?.errors.phone && (
                  <span className="text-red-500">{formik.errors.phone}</span>
                )}
              </label>

              {/* <PhoneInput
                inputProps={{
                  name: "phone",
                  id: "phone",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder="123 456 789"
                country={"mm"}
                inputClass="text-gray-500 p-lr-8 w-[100%!important] border-[1px] border-Light/active rounded-md"
                buttonClass="bg-white border border-gray-300 rounded-l-lg"
              /> */}

              <input
                id="phone"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                type="text"
                placeholder={getLanguageFile.type_phone_number}
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />
            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.password}
                <span className="text-red-500 pe-2">*</span>
                {formik?.touched.password && formik?.errors.password && (
                  <span className="text-red-500">{formik.errors.password}</span>
                )}
              </label>

              <span className="flex justify-between items-center text-gray-500  p-lr-8 w-100 border-[1px] border-Light/active rounded-md focus-within:border-blue-500">
                <input
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  placeholder={getLanguageFile.type_new_password}
                  className="w-100 p-tb-15 password-input outline-none rounded-xl"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  ) : (
                    <EyeSlashIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  )}
                </button>
              </span>
            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.confirm_new_password}
                <span className="text-red-500 pe-2">*</span>
                {formik?.touched.password_confirmation &&
                  formik?.errors.password_confirmation && (
                    <span className="text-red-500">
                      {formik.errors.password_confirmation}
                    </span>
                  )}
              </label>
              <span className="flex justify-between items-center text-gray-500  p-lr-8 w-100 border-[1px] border-Light/active rounded-md focus-within:border-blue-500">
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password_confirmation}
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={getLanguageFile.type_confirm_new_password}
                  className="w-100 p-tb-15 password-input outline-none rounded-xl"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  ) : (
                    <EyeSlashIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  )}
                </button>
              </span>
            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.my_refer_code}
                {formik?.touched.referral_code &&
                  formik?.errors.referral_code && (
                    <span className="text-red-500">
                      {formik.errors.referral_code}
                    </span>
                  )}
              </label>
              <input
                id="referral_code"
                name="referral_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.referral_code}
                type="text"
                placeholder={getLanguageFile.type_my_refer_code}
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />
            </div>

            <LoadingComponent
              loadingGroup={"signup"}
              loadingDesign={
                <button
                  disabled={true}
                  type={"submit"}
                  className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-16 disabled:cursor-not-allowed disabled:opacity-50"
                >
                  <BeatLoader color="#ffffff" size={13} />
                </button>
              }
            >
              <button
                disabled={Object.keys(formik.errors).length > 0}
                type={"submit"}
                className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-16 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
              >
                {getLanguageFile.sign_up}
              </button>
            </LoadingComponent>

            <h2 className="flex items-center justify-center m-b-8">
              <span className="fs-12 font-medium text-black text-center pe-1">
                {getLanguageFile.already_account}?
              </span>
              <button
                type="button"
                onClick={() => navigate("/signin")}
                className="text-blue-500 font-medium fs-12"
              >
                {getLanguageFile.sign_in}
              </button>
            </h2>
          </form>
        </section>
      </div>
    </>
  );
}
