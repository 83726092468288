import React, { useEffect, useState, useRef } from 'react';
import Avatar from '../../assets/icons/Avatar.png';
import { ArrowLeftIcon, PencilIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import AuthHeader from '../../layout/AuthHeader';
import Logout from '../../lib/logoutFunction';
import { useSelector } from 'react-redux';
import callApi from '../../services/api/apiClient';
import { useNavigate } from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor';
import AlertPopup from '../../components/AlertPopUp/AlertPopUp';

export default function AccountDetail() {

  const navigate = useNavigate();
  const editor = useRef(null);

  const profile = useSelector(state => state?.profile?.profile?.data?.data);
  const AccessToken = useSelector(state => state.AccessToken);
  const [logoutAlert, setLogoutAlert] = useState(false);

  const [imageEdit, setImageEdit] = useState(false);
  const [imageHolder, setImageHolder] = useState(null);
  const [displayImage, setDisplayImage] = useState();

  const [changeLanguage, setChangeLanguage ] = useState(window.localStorage.currentLanguage || 'en');
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Referral code copied to clipboard');
    }).catch((err) => {
      console.error('Could not copy text: ', err);
    });
  };


  const handleSignOut = () => {
    setLogoutAlert(true)
  };

  useEffect(() => {
    if (AccessToken) {
      callApi("profile/profile")
        .executeDispatch()
    }
  }, [AccessToken]);

  useEffect(() => {
    if (profile?.profile_image) {
      setDisplayImage(profile.profile_image);
    }
  }, [profile]);

  const handleImageChange = (event) => {
    setImageEdit(true);
    setImageHolder(event.currentTarget.files[0]);
  };

  async function cropImage() {
    if (editor) {
      editor.current.getImage().toBlob((blob) => {
        const formData = new FormData();
        formData.append('profile_image', blob, 'profile_image.png');
        callApi('profile/uploadImage')
          .withBody(formData)
          .execute()
          .then((res) => {
            if (res?.status_code === 200) {
              setImageEdit(false);
              setDisplayImage(URL.createObjectURL(blob));
              callApi("profile/profile").executeDispatch(); // Refresh profile data
            } else {
              alert('Failed to upload image');
            }
          })
          .catch((err) => {
            console.error('Error uploading image: ', err);
          });
      });
    }
  }

  const handleClose = () => {
    setLogoutAlert(false)
  };

  return (
    <>
{
        logoutAlert &&
        <AlertPopup
          type={""}
          btnLabel={getLanguageFile.signout}
          text={`${getLanguageFile.logout_your_account}?`}
          onClick={Logout}
          close={handleClose}
          cancel={getLanguageFile.cancel}
        />
      }

      <AuthHeader title={getLanguageFile.my_account} backkey={true} route="/profile" />

      <div id="body" className="auth" style={{ overflow: 'hidden auto', marginTop: '10px' }}>
        <div className="max-w-md mx-auto p-4">

          {/* Section 1: Profile Image */}
          <div className="flex justify-center my-4">
            <div className="relative">
              {imageEdit ? (
                <>
                  <AvatarEditor
                    ref={editor}
                    image={imageHolder}
                    border={50}
                    scale={1.2}
                    className="w-16 h-16 rounded-full"
                  />
                  <button onClick={cropImage} className="mt-2 p-2 bg-blue-500 text-white rounded">Save</button>
                </>
              ) : (
                <img src={displayImage || Avatar} alt={profile?.name} className="w-16 h-16 rounded-full" />
              )}
              <label className="absolute bottom-0 right-0 p-1 bg-white rounded-full w-4 h-4 flex items-center justify-center cursor-pointer">
                <input
                  type="file"
                  className="hidden"
                  onChange={handleImageChange}
                  accept="image/*"
                />
                <PencilIcon className="h-3 w-3 text-[#8C939F]" />
              </label>
            </div>
          </div>

          {/* Section 2: Account Detail Card */}
          <div className="bg-gray-100 p-4 rounded-lg mb-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="fs-14 font-bold">{getLanguageFile.account_detail}</h2>
              <button onClick={() => navigate('/edit-account-detail')} className="text-blue-500 flex items-center font-bold">
                <PencilSquareIcon className="h-4 w-4 text-blue-500" />
                <span className="ml-1 fs-12">{getLanguageFile.edit}</span>
              </button>
            </div>
            <div className="flex justify-between pt-2 pb-2">
              <p className="text-500 font-normal fs-12">{getLanguageFile.username}</p>
              <p className="text-500 font-semibold fs-12">{profile?.name}</p>
            </div>
            <div className="flex justify-between pt-2 pb-2">
              <p className="text-500 font-normal fs-12">{getLanguageFile.phone_number}</p>
              <p className="text-500 font-semibold fs-12">{profile?.phone}</p>
            </div>
            <div className="flex justify-between items-center pt-2 pb-2">
              <p className="text-500 font-normal fs-12">{getLanguageFile.my_refer_code}</p>
              <button onClick={() => copyToClipboard(profile?.referral_code)} className="flex items-center">
                <p className="text-500 font-semibold mr-1 fs-12">{profile?.referral_code}</p>
                <ClipboardIcon className="h-4 w-4 text-blue-500" />
              </button>
            </div>
            <button onClick={() => navigate('/reset-password')} className="text-blue-500 font-normal pt-2 pb-2 fs-12">{getLanguageFile.reset_password}</button>
          </div>

          {/* Section 3: Shipping Info Card */}
          <div className="bg-gray-100 p-4 rounded-lg mb-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="fs-14 font-bold">{getLanguageFile.shipping_info}</h2>
              <button onClick={() => navigate('/edit-shipping-info')} className="text-blue-500 flex items-center font-bold">
                <PencilSquareIcon className="h-4 w-4 text-blue-500" />
                <span className="ml-1 fs-12">{getLanguageFile.edit}</span>
              </button>
            </div>
            <div className="flex justify-between mb-2">
              <p className="text-500 font-normal fs-12">{profile?.region?.name}</p>
            </div>
            <div className="flex justify-between mb-2">
              <p className="text-500 font-normal fs-12">{profile?.township?.name}</p>
            </div>
            <div className="flex justify-between mb-2">
              <p className="text-500 font-normal fs-12">{profile?.address}</p>
            </div>
          </div>

          <button
            onClick={handleSignOut}
            className="flex items-center justify-center border-[1px] border-foundation-grey-normal-hover hover:bg-red-500 text-500 hover:text-white font-semibold rounded-full py-3 px-8 w-full mt-6 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
          >
            { getLanguageFile.signout }
          </button>
        </div>
      </div>
    </>
  );
}
