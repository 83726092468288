import React, { useState } from "react";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/solid"; // Importing StarIcon from Heroicons
import callApi from "../../services/api/apiClient";

const ReviewModal = ({
  productDetail,
  visible,
  onClose,
  setAlertmessage,
  setServerError,
}) => {
  const [rating, setRating] = useState(0); // Rating value from 0 to 5
  const [comment, setComment] = useState(""); // Comment text

  // Function to handle rating by clicking stars
  const handleRating = (selectedRating) => {
    setRating(selectedRating);
  };

  // Function to post the review
  const postReview = () => {
    if (productDetail) {
      callApi("rating/product")
        .withBody({
          product_id: productDetail?.id,
          rating: rating,
          comment: comment,
        })
        .execute()
        .then((res) => {
          if (res?.status_code == 200) {
            callApi("products/productDetail")
              .withHeaders({
                "X-localization": window.localStorage.currentLanguage,
              })
              .withKeyParameter({ productSlug: productDetail?.slug })
              .loadingGroup("productDetail")
              .executeDispatch();
            // callApi("review/getAll").executeDispatch();
            // callApi("review/getuserReview").executeDispatch();
            setAlertmessage("Your review has been uploaded");
            onClose();
          } else {
            setServerError(res?.message || "Unexpected error occured");
          }
        });
    } else {
      callApi("review/post")
        .withBody({
          rating: rating,
          comment: comment,
        })
        .execute()
        .then((res) => {
          if (res?.status_code == 200) {
            callApi("review/getAll").executeDispatch();
            callApi("review/getuserReview").executeDispatch();
            setAlertmessage("Your review has been uploaded");
            onClose();
          } else {
            setServerError(res?.message || "Unexpected error occured");
          }
        });
    }
  };

  if (!visible) return null; // Do not render the modal if not visible

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-96 text-center">
        {/* Close Button */}
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition duration-200"
          onClick={onClose}
        >
          <XMarkIcon className="w-6 h-6" />
        </button>

        <h2 className="text-xl font-bold mb-4">Rate and Review</h2>

        {/* Rating Stars */}
        <div className="flex justify-center mb-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              onClick={() => handleRating(star)}
              className="focus:outline-none"
            >
              <StarIcon
                id="StarIcon"
                className={`w-8 h-8 ${
                  star <= rating ? "text-yellow-500" : "text-gray-400"
                }`}
              />
            </button>
          ))}
        </div>

        {/* Comment Box */}
        <textarea
          className="w-full h-24 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          placeholder="Write your comment here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />

        {/* Post Button */}
        <button
          className="bg-gold-500 text-500 py-2 px-4 rounded-full font-semibold fs-14 transition duration-200"
          onClick={postReview}
        >
          Post Review
        </button>
      </div>
    </div>
  );
};

export default ReviewModal;
